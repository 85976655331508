<template>
  <v-container class="px-3 px-md-7 pt-10">
    <v-row class=" font-weight-light fill-height pa-2">
      <v-col cols="12"><h1>מרכז הידע</h1></v-col>
      <v-col cols="4">
          ברוכים הבאים למרכז הידע של כמה כמה, המדריכים בחלק זה של האתר ילוו אתכם ויעזרו לכם להכיר את עולם המשכנתאות
          בצורה טובה יותר
      </v-col>
      <v-col cols="12">
          <BlogPosts/>
      </v-col>          
    </v-row>
  </v-container>
</template>

<script>

import BlogPosts from "../components/BlogPosts";

export default {
  name: "KnowledgeBase",
  components: {BlogPosts},
}
</script>

<style scoped>
body {
  font-size: 1em;
}
</style>