<template>
  <v-container class="theme--light">
    <v-row>
      <v-col cols="12" md="4" v-for="blogpost in blogPosts" :key="blogpost.id">
        <v-row>
          <v-col cols="12" md="4"  v-html="blogpost.svg"></v-col>
        </v-row>
        <h3 class="pb-1">
          <router-link :to='"/post/" + blogpost.id'>{{ blogpost.title }}</router-link>
        </h3>
        <v-row class="py-5 ">
          <v-chip
              class=""
              small
          >
            {{ blogpost.tag }}
          </v-chip>
          &nbsp;&nbsp;&nbsp;
          <h6>
            <v-icon light right>{{mdiCalendar}}</v-icon>
            {{ blogpost.publish_date }}
          </h6>
          <h6
          >
            <v-icon light right class="pr-2">{{ mdiAccountCircle }}</v-icon>
            אמנון מחרבי
          </h6>
        </v-row>
        <p>{{ blogpost.shortDescription }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  props: ['title'],
  data: () => ({}),
  computed: {
    ...mapGetters(['blogPosts']),
  }

}
</script>

<style scoped>
p {
  font-size: 1em;
  line-height: 1.2em;
}
</style>