<template>
  <v-container fluid>
    <div class="d-flex justify-center text--deep-purple font-weight-light fill-height" style="font-size: 3em">
      <div class>
        הצהרה משפטית
      </div>
    </div>
    <div class="d-flex justify-center text--deep-purple font-weight-light fill-height" style="font-size: 1em">
      <div>
        <h1>מדיניות פרטיות</h1>
        <p>
          א. החברה מכבדת את פרטיותם של המשתמשים הנכנסים לאתר ו/או המשתמשים בשירותים השונים המוצעים במסגרתו. לפיכך,
          מנהיגה “KamaKama” מדיניות פרטיות, כפי שתוצג באתר, אשר מטרתה להציג את האופן שבו היא משתמשת במידע שנמסר לה על
          ידי המשתמשים באתר ו/או נאסף על ידה בעת השימוש באתר וכן את האופן שבו מבצע המשתמש שימוש בתוכן המצוי באתר ו/או
          אליו נחשף המשתמש באמצעותו.
        </p>
        <p>
        ב. תנאי מדיניות הפרטיות המפורטים באתר, מהווים חלק בלתי נפרד מן התקנון ותנאי השימוש. “KamaKama” רשאית לשנות מעת
        לעת, ועל דעתה בלבד, את הוראות מדיניות הפרטיות שלה, כדי שישקפו שינויים טכנולוגיים, עסקיים, משפטיים או
        רגולטורים.
        </p>
        <hr>
        <h1>רישום</h1>
        <p>
          א. במסגרת השירותים שמציעה “KamaKama” למשתמשי האתר, ובכלל זה ביצוע פעולות מסוימות, קבלת פרסומים או מבצעים,
          תיתכן דרישה לביצוע הרשמה במהלכה יידרש המשתמש למסור מידע אישי, כדוגמת שם, כתובת, דרכי ההתקשרות או כתובת דואר
          האלקטרוני. השדות, שחובה למלא, יסומנו במפורש. יובהר כי בלי למסור את הנתונים המתבקשים בשדות החובה לא יוכל משתמש
          להירשם לשירותים הטעונים רישום (להלן: “ההרשמה“). כחלק בלתי נפרד מהליך ההרשמה לאתר וכתנאי לסיום תהליך הרישום,
          תהא “KamaKama” רשאית לאמת את זהותו של המשתמש בהתאם למידע שנמסר לה על ידו.
        </p>
        <p>
        ב. מילוי חלק מהשדות בהליך הרישום לאתר מהווה תנאי הכרחי לשימוש לסיום הליך ההרשמה. המשתמש מצהיר ומתחייב בזאת, כי
        כל המידע שימסור ו/או יעדכן באתר, הנו נכון אמין ומדויק וכן כי ביצע את ההרשמה או מסר את המידע בשמו ועבור עצמו
        בלבד ולא בשם או עבור צדדים שלישיים, למעט במקרים בהם הורשה לעשות כן, במפורש ועל פי דין. המידע שמסר המשתמש וכן
        מידע נוסף שיצטבר אודותיו ישמרו במאגר המידע של החברה ויעשה בו שימוש בהתאם למדיניות הפרטיות של “KamaKama” ובהתאם
        להוראות כל דין.
        </p>
        <p>
          ג. מובהר בזאת, כי לא חלה על המשתמש כל חובה שבדין למסור את המידע כאמור וכן שמסירת המידע תלויה ברצונו ובהסכמתו
          של המשתמש. הרישום באתר באמצעות סימון “הסכמה לקבלת דיוור אלקטרוני” יהווה הסכמה אקטיבית ופוזיטיבית מטעם המשתמש
          לקבלת חומר פרסומי ושיווקי החברה או גורמים אחרים אשר יעניקו לחברה שירותים או הטבות, באמצעי המדיה השונים לרבות
          באמצעות דיוור ישיר.
        </p>
        <p>

          ד. משתמש שנרשם באתר זכאי לבקש מהחברה בכל עת, בהודעה בכתב למען החברה, בהודעה פקס או במשלוח אימייל, שהמידע
          המתייחס אליו יימחק ממאגר המידע של החברה וכי ברצונו למחוק את רישומו ברשימת הדיוור של החברה. במקרה זה החברה תהא
          רשאית, בהתאם ובכפוף להוראות הדין החל, להיענות לבקשתו.
        </p>
        <p>
          ה. המשתמש מסכים בזאת באופן מפורש לשימוש האמור לעיל במידע ומאשר כי השימוש בו לא ייחשב כפגיעה בפרטיות המשתמש ולא
          יזכה אותו בכל סעד ו/או פיצוי כלשהו.
        </p>
        <p>
          ו. בכל מקרה של ביטול רישום, יבוטל הרישום החל מתחילת החודש הקלנדרי העוקב שלאחר מסירת הודעה בנושא.
        </p>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "LegalView"
}
</script>

<style scoped>

</style>