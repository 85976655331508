<template>
  <v-app>
    <AppBar/>
    <SideBar/>

    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer app dark padless light inset absolute>
      <v-card class="primary white--text text-center" width="100%">
        <v-card-text style="width: 100%; padding: 2px">
<!--          <v-btn-->
<!--              class="mx-6 white&#45;&#45;text"-->
<!--              icon-->
<!--          >-->
<!--            <v-icon size="24px">-->
<!--              {{ mdiFacebook }}-->
<!--            </v-icon>-->
<!--          </v-btn>-->
<!--          <v-btn-->
<!--              class="mx-6 white&#45;&#45;text"-->
<!--              icon-->
<!--          >-->
<!--            <v-icon size="24px">-->
<!--              {{ mdiTwitter }}-->
<!--            </v-icon>-->
<!--          </v-btn>-->
          <h6>
            <v-container fluid class="pa-0">
              <v-row justify="center" no-gutters>
                <router-link class="white--text mx-3" to="/legal">מדיניות פרטיות - תנאי שימוש</router-link>
                <router-link class="white--text mx-3" to="/release-notes">{{ version.version }} - <b>{{ version.date }} </b></router-link>
              </v-row>
            </v-container>
          </h6>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>
<style>
.v-tab .v-text-field > .v-input__control > .v-input__slot:before {
  border-color: inherit;
  border-width: 0;
}
</style>
<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import AppBar from "@/components/nav/AppBar";
import SideBar from "@/components/nav/SideBar";

export default {
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'מחשבונים פיננסים',
    // all titles will be injected into this template
    titleTemplate: '%s | כמה כמה',

  },
  async mounted() {
    await this.$store.dispatch("initUser");
    await this.fetchVersion();
  },
  watch: {
    // $route(to) {
    //   console.log(to)
    //   const mortgageId = to.params.id;
    //   this.$store.dispatch("getMortgage", mortgageId);
    // },
    pageTitle: function (pageTitle) {
      document.title = pageTitle;
    },
  },
  methods: {
    ...mapMutations({
      addDefaultLoan: "addDefaultLoan",
    }),
    ...mapActions({
      fetchVersion: "fetchVersion",
      newMortgage: "newMortgage",
    }),
  },
  components: {
    AppBar,
    SideBar
  },
  computed: {
    ...mapState({
      mortgageList: (state) => state.mortgageList,
      user: (state) => state.user,
    }),
    ...mapGetters(["pageTitle", "version"]),
  },

  data: () => ({
    drawer: false,
    // icons: ["mdiFacebook", "mdi-twitter"],
    //icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  }),
};
</script>
