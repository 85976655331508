<template>
  <v-dialog
    :value="showLoginPage"
    @click:outside="closeLoginPage"
    max-width="900"
  >
    <template v-slot:activator="{}">
      <v-btn text @click="toggleLoginPageHelper">
        {{ loginButtonText }}
        <v-icon colo>{{ mdiAccount }}</v-icon>
      </v-btn>
    </template>
    <v-flex xs12>
      <v-card class="elevation-12">
        <v-card-text class="pa-1">
          <v-row>
            <v-col md="6" class="loginCta"></v-col>
            <v-col md="6" class="pa-15 login">
              <h1 class="align-center">הגיע הזמן שלכם לחסוך</h1>
              <h4 class="align-center">התחברות/הירשמות באמצעות:</h4>
              <GoogleLogin></GoogleLogin>
              <FacebookLogin></FacebookLogin>
              <v-divider class="my-5"></v-divider>
              <h4 class="align-center">או התחברו באמצעות מייל:</h4>
              <v-form>
                <v-text-field
                  prepend-inner-icon="$vuetify.icons.mdiAccount"
                  name="email"
                  flat
                  outlined
                  label="דואר אלקטרוני"
                  type="text"
                  v-model="email"
                ></v-text-field>
                <v-text-field
                  id="password"
                  flat
                  outlined
                  prepend-inner-icon="$vuetify.icons.mdiLock"
                  name="password"
                  label="סיסמא"
                  type="password"
                  v-model="password"
                ></v-text-field>
              </v-form>
              <v-btn
                @click="login"
                dark
                block
                x-large
                color="deep-purple accent-4"
                >התחבר</v-btn
              >
              <span class="d-none align-center full-width todo">
                עדיין לא נרשמת?
                <a @click="openRegistrationSection()" class="mx-1">הירשם</a>
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-dialog>
</template>

<script>
import GoogleLogin from "./GoogleLogin";
import {mapActions, mapGetters, mapState} from "vuex";
import FacebookLogin from "./FacebookLogin";
export default {
  components: { GoogleLogin, FacebookLogin },

  data: () => ({
    email: "",
    password: "",
    abc: false,
  }),
  methods: {
    ...mapActions({
      toggleLoginPage: "toggleLoginPage",
      showRegistrationFormAction: "showRegistrationForm",
      registerUser: "registerUser",
    }),
    openRegistrationSection() {
      this.showRegistrationFormAction(true);
    },
    closeRegistrationSection() {
      this.showRegistrationFormAction(false);
    },
    closeLoginPage() {
      this.toggleLoginPage(false);
    },
    toggleLoginPageHelper() {
      this.toggleLoginPage(!this.showLoginPage);
    },
    login() {
      this.$store.dispatch("login", {
        user: this.email,
        password: this.password,
      });
    },
    register() {
      this.registerUser({ email: this.email, password: this.password });
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    loginButtonText: function () {
      return this.user.loggedIn ? "מחובר" : "התחבר";
    },
    ...mapGetters({
      showLoginPage: "showLoginPage",
      showRegistrationForm: "showRegistrationForm"
    }),
  },
  name: "Login",
};
</script>

<style scoped>
</style>
