<template>
  <div style="margin-bottom: 4px" class="period-select-root">
    <fieldset>
      <legend style="top: 0; padding: 0; margin: 0; width: 39px; text-align: right; bottom: 2px;"
              class="v-text-field--outlined">
        <label class="v-label v-label--active theme--light"
               style="right: -3px; top: 16px; position: absolute; ">תקופה</label>
      </legend>
      <div>
        <v-select-alt
            :dir="$vuetify.rtl ? 'rtl' : 'ltr'"
            :clearable="false"
            type="number"
            :reduce="o => o.code"
            :options="options"
            :value='value'
            @input="update"
            class="period-select"
            v-bind:class="{ smallText: shouldDisplaySmallerLabel}"
        ></v-select-alt>

      </div>
    </fieldset>

  </div>

</template>

<script>
import "vue-select/dist/vue-select.css";

export default {
  props: {
    min: Number, // Min month
    max: Number, // Max month
    value: Number,
    showMonthsLabel: {
      default: false,
      type: Boolean
    },
    mode: {
      default: 'monthly',
      type: String
    }
  },
  methods: {
    update(newValue) {
      this.$emit('input', newValue);
    }
  },
  name: "MonthlyPeriodSelect",
  computed: {
    size: vm => vm.max - vm.min,
    options: vm => {

      // eslint-disable-next-line no-unused-vars
      let filter = (month) => true;
      if (vm.mode === "yearly") {
        filter = month => month % 12 === 0;
      }

      function getLabel(months) {
        let output = months;
        if (months === 0) {
          return output;
        }
        if (months === 12) {
          return output + " (שנה אחת) "
        }
        if (months === 24) {
          return output + " (שנתיים) "
        }
        if (months % 12 === 0) {
          return output + " (" + (months / 12) + " שנים)";
        }
        if (vm.showMonthsLabel) {
          return output + " (חודשים) "
        }
        return output;

      }

      return Array.from(Array(vm.size), (_, month) => month + (vm.min)).filter(filter).map(item => {
        return {
          label:
              getLabel(item),
          code: item
        }
      });
    },
    shouldDisplaySmallerLabel: vm => {
      if (vm.options && vm.options[vm.value - 1] && vm.options[vm.value - 1].label)
        return vm.options[vm.value - 1].label.toString().length > 3;
      else {
        return false;
      }
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/colors";

.period-select {
  .vs__search {
    //min-width: 100px;
  }

  .vs__dropdown-toggle {
    //border-color: $kamakama-purple !important;
    border-color: transparent;
    height: 38px;
    background-color: rgba(0, 0, 0, 0);
  }
}

.period-select-root {
  .smallText {
    .vs__selected-options {
      font-size: 0.80em;
      margin-top: 4px;
    }
  }

  fieldset {
    padding: 0;
    margin: 0;
    border-radius: 4px;
    border-color: #6200EA;
    border-width: 1px;
    padding-inline-start: 0.6em;
  }
}
</style>
