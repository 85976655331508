///A -> Accrued Amount (principal + interest)
// P -> Principal
// r -> Interest Rate
// n -> Number of times the interest is applied per time period
// t -> number of time periods elapsed
export default class SimpleCompoundInterest {

    public get A(): number {
        return (this.P * (Math.pow(1 + (this.r / this.n), this.n * this.t))) + this.FV;
    }

    public get FV(): number {
        return (this.pmt * ((Math.pow((1 + (this.r / this.n)), this.n * this.t) - 1) / (this.r / this.n)))
    }

    public get Profit(): number {
        return this.A - this.P;
    }

    P: number;
    r: number;
    n: number;
    t: number;
    pmt: number;

    constructor(P: number, r: number, n: number, t: number, pmt: number) {
        this.P = P;
        this.r = r;
        this.n = n;
        this.t = t;
        this.pmt = pmt;
    }
}