<template>
  <v-container fluid class="px-3 px-md-7 pt-10">
    <v-row class="d-flex justify-center align-center text--deep-purple font-weight-light"
           style="font-size: 2em">
      מחשבון ריבית דריבית
    </v-row>
    <v-row class="d-flex flex-column justify-center align-center text--deep-purple font-weight-light">
      <v-text-field
          label="סכום ראשוני"
          placeholder="סכום ראשוני"
          outlined
          dense
          type="number"
          class="field-styles"
          v-model="principal"
      ></v-text-field>
      <MonthlyPeriodSelect
          v-bind:min="1"
          v-bind:max="1200"
          v-model="period"
          class="field-styles"
          show-months-label
      ></MonthlyPeriodSelect>
      <v-text-field
          label="ריבית"
          placeholder="ריבית"
          outlined
          dense
          type="number"
          class="field-styles"
          v-model="interest"
      ></v-text-field>
      <v-text-field
          label="תוספת חודשית"
          placeholder="תוספת חודשית"
          outlined
          dense
          type="number"
          class="field-styles"
          v-model="pmt"
      ></v-text-field>
      <v-alert
          border="top"
          colored-border
          color="deep-purple accent-4"
          elevation="2"
          class="field-styles"
          style="font-size: 1.5em; font-weight: bold"
      >
        <Money :value="this.calculator.A"/>
      </v-alert>
      <v-alert
          border="top"
          colored-border
          color="deep-purple accent-4"
          elevation="2"
          class="field-styles"
          style="font-size: 1em;"
      >
        {{'השקעה של '}}
        <b>{{numberToFormattedString(principal)}}</b>
        {{'₪'}}
        <br>
        {{'למשך '}}
        {{period}}
        {{'חודשים, '}}
<br>
        {{'בריבית של'}}
        {{interest}}%,
        <br v-if="pmt !== '0'">
        {{pmt !== '0' ? ('בתוספת חודשית של ' + numberToFormattedString(pmt) + ' ₪') : ""}}
        <br>

        {{'תניב'}}
        <br>
        {{numberToFormattedString(this.calculator.A)}}
        {{'₪'}}
        <br>
        {{'שמתוכם'}}
        <br>
        <b>{{ numberToFormattedString(this.calculator.A - (this.calculator.Profit - (this.pmt * this.period)))}}</b>
        <b>{{' ₪ '}}</b>הפקדות
        <br>
        <b>{{ numberToFormattedString(this.calculator.Profit - (this.pmt * this.period))}}</b>
        <b>{{' ₪ '}}</b>ריבית
      </v-alert>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <v-btn class="mx-2" color="deep-purple accent-4" small outlined style="z-index: 1"
               @click="snackbar = true; copyToClipboard(currentUrl)">
          <v-icon small left> {{ mdiShareVariant }}</v-icon>
          שיתוף
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-snackbar
          v-model="snackbar"
          timeout="5000">
        קישור הועתק!
        <template v-slot:action="{ attrs }">
          <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            סגור
          </v-btn>

        </template>

      </v-snackbar>
    </v-row>
  </v-container>
</template>

<script>
import SimpleCompoundInterest from '../../utils/loan/simpleCompoundInterest';
import Money from "@/components/common/Money";
import MonthlyPeriodSelect from "@/components/common/MonthlyPeriodSelect";
import {numberToFormattedString as formatter} from "@/utils/numberFormats";

export default {
  components: {MonthlyPeriodSelect, Money},
  name: "CompoundInterestView",
  metaInfo: {
    title: 'מחשבון ריבית',
    titleTemplate: '%s | כמה כמה',
  },
  data: () => ({
    principal: 0,
    period: 0,
    interest: 0,
    pmt: 0,
    calculator: null,
    snackbar: false
  }),
  computed: {
    currentUrl: function () {
      return window.location.href;
    },
  },
  methods: {
    numberToFormattedString(number) {
      return formatter(number);
    },
    fieldUpdated() {
      this.calculator = new SimpleCompoundInterest(this.principal, this.interest / 100, 12, this.period / 12, this.pmt)
      this.$router.replace({
        name: 'compoundInterestView',
        params: {principal: this.principal, interest: this.interest, period: this.period, pmt: this.pmt}
      }).catch(() => {
      });
    },
    copyToClipboard(str) {
      const el = document.createElement('textarea');  // Create a <textarea> element
      el.value = str;                                 // Set its value to the string that you want copied
      el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
      el.style.position = 'absolute';
      el.style.left = '-9999px';                      // Move outside the screen to make it invisible
      document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
      const selected =
          document.getSelection().rangeCount > 0        // Check if there is any content selected previously
              ? document.getSelection().getRangeAt(0)     // Store selection if found
              : false;                                    // Mark as false to know no selection existed before
      el.select();                                    // Select the <textarea> content
      document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
      document.body.removeChild(el);                  // Remove the <textarea> element
      if (selected) {                                 // If a selection existed before copying
        document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
        document.getSelection().addRange(selected);   // Restore the original selection
      }
    }
  },
  watch: {
    principal: function () {
      this.fieldUpdated();
    },
    period: function () {
      this.fieldUpdated();
    },
    interest: function () {
      this.fieldUpdated();
    },
    pmt: function () {
      this.fieldUpdated();
    },
  },
  beforeMount() {
    ///compound-interest/:principal?/:interest?/:period/:pmt?
    //this.$store.commit('toggleDrawerState', false);
    let inputs = this.$route.params;
    this.principal = Number(inputs.principal || 200000);
    this.period = Number(inputs.period || 240);
    this.interest = Number(inputs.interest || 2);
    this.pmt = Number(inputs.pmt || 0);
    this.calculator = new SimpleCompoundInterest(this.principal, this.interest / 100, 12, this.period / 12, this.pmt)

  }
}
</script>

<style scoped>
.field-styles {
  margin-top: 5px;
  max-width: 300px;
  width: 300px;
}
</style>