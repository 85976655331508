const types =
    {
        PRIME: 'PRIME',
        FIXED_NOT_INDEXED: 'FIXED_NOT_INDEXED', // קבועה לא צמודה
        FIXED_INDEXED: 'FIXED_INDEXED', // קבועה צמודה
        VARIABLE_NOT_INDEXED: 'VARIABLE_NOT_INDEXED', // משתנה לא צמודה
        VARIABLE_INDEXED: 'VARIABLE_INDEXED', // משתנה צמודה
    };

export function getText(type) {
    switch (type) {
        case types.PRIME:
            return "פריים"
        case  types.FIXED_NOT_INDEXED:
            return "קבועה לא צמודה"
        case  types.FIXED_INDEXED:
            return "קבועה צמודה"
        case  types.VARIABLE_NOT_INDEXED:
            return "משתנה לא צמודה"
        case  types.VARIABLE_INDEXED:
            return "משתנה צמודה"
    }
}

export default types;
