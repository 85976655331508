<template>
  <div>
    <v-btn block outlined @click="logInWithFacebook" large color="primary">
      <v-icon dark left> {{mdiFacebook}} </v-icon>
      Login with Facebook
    </v-btn>
  </div>
</template>
<script>
// import axios from "axios";

import { mapActions } from "vuex";

export default {
  name: "FacebookLogin",
  methods: {
    ...mapActions({
      loginWithFacebook: "loginWithFacebook",
    }),
    async logInWithFacebook() {
      await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      await this.initFacebook();
      window.FB.login(
        (response) => {
          if (response.authResponse) {
            window.FB.api("/me", { fields: "email" }, (user) => {
              user = {
                ...user,
                ...response.authResponse,
              };
              this.loginWithFacebook(user);
            });
          } else {
            alert("User cancelled login or did not fully authorize.");
          }
        },
        { scope: "email", return_scopes: true }
      );
      return false;
    },
    async initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: "204074321755855", //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          version: "v11.0",
        });
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
  },
};
</script>
<style>
.button {
  color: white;
  min-width: 150px;
  background-color: #000000a1;
  height: 2.5rem;
  border-radius: 2rem;
  font-weight: 400;
  font-size: 0.8rem;
}
</style>
