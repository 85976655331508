import Vue from 'vue';
import Vuetify from 'vuetify/lib';
//import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);


import {
    mdiCalculator,
    mdiLogout,
    mdiFacebook,
    mdiTwitter,
    mdiContentDuplicate,
    mdiCalendar,
    mdiAccountCircle,
    mdiPlusCircleOutline,
    mdiDotsVertical,
    mdiMinusCircleOutline,
    mdiHelpCircleOutline,
    mdiAccount,
    mdiApi,
    mdiLock,
    mdiHome,
    mdiSubdirectoryArrowLeft,
    mdiShareVariant,
    mdiContentSave,
    mdiFileOutline,
    mdiDotsHorizontal,
    mdiDelete,
    mdiPlus,
    mdiSwapHorizontal,
    mdiHeart,
    mdiPencil,
    mdiArrowRightBoldBoxOutline,
    mdiArrowLeftBoldBoxOutline
} from "@mdi/js";



export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#6200ea',
            }
        }
    },
    rtl: true,
    icons: {
        iconfont: 'mdiSvg',
        values: {
            mdiCalculator,
            mdiLogout,
            mdiFacebook,
            mdiTwitter,
            mdiContentDuplicate,
            mdiCalendar,
            mdiAccountCircle,
            mdiPlusCircleOutline,
            mdiDotsVertical,
            mdiMinusCircleOutline,
            mdiHelpCircleOutline,
            mdiAccount,
            mdiLock,
            mdiApi,
            mdiHome,
            mdiSubdirectoryArrowLeft,
            mdiShareVariant,
            mdiContentSave,
            mdiFileOutline,
            mdiDotsHorizontal,
            mdiDelete,
            mdiPlus,
            mdiSwapHorizontal,
            mdiHeart,
            mdiPencil,
            mdiArrowRightBoldBoxOutline,
            mdiArrowLeftBoldBoxOutline,
        }
    },
});
