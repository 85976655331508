<template>
  <v-container fluid class="px-3 px-md-7 pt-10">
    <v-row class="d-flex justify-center align-center text--deep-purple font-weight-light"
           style="font-size: 2em">
      מחשבון תשואה משכירות
    </v-row>
    <v-row class="d-flex flex-column justify-center align-center text--deep-purple font-weight-light">
      <v-text-field
          label="מחיר הנכס"
          placeholder="מחיר הנכס"
          outlined
          dense
          type="number"
          class="field-styles"
          v-model="initialCost"
      ></v-text-field>
      <v-text-field
          label="שכר דירה חודשי"
          placeholder="שכר דירה חודשי"
          outlined
          dense
          type="number"
          class="field-styles"
          v-model="rent"
      ></v-text-field>
      <v-text-field
          label="הוצאות חודשיות"
          placeholder="הוצאות חודשיות"
          outlined
          dense
          type="number"
          class="field-styles"
          v-model="monthlyCost"
      ></v-text-field>
      <v-alert
          border="top"
          colored-border
          color="deep-purple accent-4"
          elevation="2"
          class="field-styles"
          style="font-size: 1.5em; font-weight: bold"
      >
        <Money :value="100 * (this.rent * 12 - this.monthlyCost * 12) / this.initialCost" :maximum-fraction-digits="2"
               :minimum-fraction-digits="2" symbol="%"/>
      </v-alert>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <v-btn class="mx-2" color="deep-purple accent-4" small outlined style="z-index: 1"
               @click="snackbar = true; copyToClipboard(currentUrl)">
          <v-icon small left> {{ mdiShareVariant }}</v-icon>
          שיתוף
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-snackbar
          v-model="snackbar"
          timeout="5000">
        קישור הועתק!
        <template v-slot:action="{ attrs }">
          <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            סגור
          </v-btn>

        </template>

      </v-snackbar>
    </v-row>
  </v-container>
</template>

<script>
import Money from "@/components/common/Money";

export default {
  components: {Money},
  name: "RentalYieldView",
  metaInfo: {
    title: 'מחשבון תשואה משכירות',
    titleTemplate: '%s | כמה כמה',
  },
  data: () => ({
    initialCost: 0,
    period: 0,
    monthlyCost: 0,
    rent: 0,
    calculator: null,
    snackbar: false
  }),
  computed: {
    currentUrl: function () {
      return window.location.href;
    },
  },
  methods: {
    fieldUpdated() {
      this.$router.replace({
        name: 'rentalYieldView',
        params: {initialCost: this.initialCost, monthlyCost: this.monthlyCost, rent: this.rent}
      }).catch(() => {
      });
    },
    copyToClipboard(str) {
      const el = document.createElement('textarea');  // Create a <textarea> element
      el.value = str;                                 // Set its value to the string that you want copied
      el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
      el.style.position = 'absolute';
      el.style.left = '-9999px';                      // Move outside the screen to make it invisible
      document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
      const selected =
          document.getSelection().rangeCount > 0        // Check if there is any content selected previously
              ? document.getSelection().getRangeAt(0)     // Store selection if found
              : false;                                    // Mark as false to know no selection existed before
      el.select();                                    // Select the <textarea> content
      document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
      document.body.removeChild(el);                  // Remove the <textarea> element
      if (selected) {                                 // If a selection existed before copying
        document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
        document.getSelection().addRange(selected);   // Restore the original selection
      }
    }
  },
  watch: {
    initialCost: function () {
      this.fieldUpdated();
    },
    period: function () {
      this.fieldUpdated();
    },
    monthlyCost: function () {
      this.fieldUpdated();
    },
    rent: function () {
      this.fieldUpdated();
    },
  },
  beforeMount() {
    ///compound-monthlyCost/:initialCost?/:monthlyCost?/:period/:rent?
    //this.$store.commit('toggleDrawerState', false);
    let inputs = this.$route.params;
    this.initialCost = Number(inputs.initialCost || 1500000);
    this.period = Number(inputs.period || 240);
    this.monthlyCost = Number(inputs.monthlyCost || 400);
    this.rent = Number(inputs.rent || 4800);

  }
}
</script>

<style scoped>
.field-styles {
  margin-top: 5px;
  max-width: 300px;
  width: 300px;
}
</style>