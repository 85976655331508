<template>
   <v-container class="">
      <v-row v-if="blogPosts[logRoute]">
          <v-col cols="2" class="mt-6">
          </v-col>
          <v-col md="8">
              <v-row class="my-1">
                  <v-col md="1" v-html="blogPosts[logRoute].svg"></v-col>
                  <v-col md="11"><h1>{{ blogPosts[logRoute].title}}</h1></v-col>
              </v-row>
              <v-row class="my-2 mr-1">
                <h6>
                    <v-icon light right class="pr-2">{{mdiCalendar}}</v-icon>
                    {{ blogPosts[logRoute].publish_date }}
                </h6>
                <h6>
                    <v-icon light right class="pr-2">{{ mdiAccountCircle }}</v-icon>
                    אמנון מחרבי
                </h6>
                <v-chip
                class="mr-2"
                small
                >
                {{ blogPosts[logRoute].tag }}
                </v-chip>
            </v-row>
              <v-card elevation="2">
                  <v-card-title></v-card-title>
                  <v-card-subtitle>{{ blogPosts[logRoute].shortDescription}}</v-card-subtitle>
                  <v-card-text>{{ blogPosts[logRoute].blogBody}}</v-card-text>
                  <v-card-actions><router-link to="/knowledge-base">חזרה למרכז הידע</router-link></v-card-actions>
              </v-card>
          </v-col>    
      </v-row>
      <v-row v-else>404</v-row>
    </v-container>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    props: ['title'],

    data: () => ({
    }),
    computed: {
        logRoute: function () {
            return this.$router.currentRoute.params.id;
        },
        ...mapGetters(['blogPosts']),
    }
    
}
</script>

<style scoped >
p{font-size: 1em; line-height: 1.2em;}
</style>