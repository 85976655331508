import paymentType from "@/utils/scheduleType";
import loanType from "@/utils/loanType";

export function getDefaultLoan() {
    return new Loan({
        name: "defaultName",
        amortizationScheduleType: paymentType.FIXED_PAYMENT,
        principal: 200000,
        interestType: loanType.PRIME,
        interest: 1,
        period: 60,
        gracePeriod: 0,
        principalEliminationChangeIntervals: [],
        principalEliminationStrategy: "shortenPeriod",
        interestChangeIntervals: [],
        cpi: 2,
        cpiChangeIntervals: []
    });
}


class ScheduleMonth {
    month: number;
    principalLeft: number;
    thisMonthPayment: number;
    interestPayment: number;
    principalPart: number;
    grace: string; // "Grace / No Grace"
    interest: number;
    cpi: number;
    interestRatio: number;

    constructor(month: number,
                principalLeft: number,
                thisMonthPayment: number,
                interestPayment: number,
                principalPart: number,
                grace: string,
                interest: number,
                cpi: number,
                interestRatio: number) {
        this.month = month;
        this.principalLeft = principalLeft;
        this.thisMonthPayment = thisMonthPayment;
        this.interestPayment = interestPayment;
        this.principalPart = principalPart;
        this.grace = grace;
        this.interest = interest;
        this.cpi = cpi;
        this.interestRatio = interestRatio;
    }
}

class LoanResult {
    totals: object;
    schedule: any[];

    constructor(totals: object, schedule: ScheduleMonth[]) {
        this.totals = totals;
        this.schedule = schedule;


    }

}


interface IntervalChange {
    when: number;
    value: number;
}

interface LoanOptions {
    name: string;
    amortizationScheduleType: string;
    principal: number;
    interestType: string;
    interest: number;
    period: number;
    gracePeriod: number;
    principalEliminationChangeIntervals: any[];
    interestChangeIntervals: any[];
    cpi: number;
    cpiChangeIntervals: any[];
    principalEliminationStrategy: string;
}

export default class Loan {
    name: string;
    amortizationScheduleType: string;
    principal: number;
    interestType: string;
    interest: number;
    period: number;
    gracePeriod: number;
    principalEliminationChangeIntervals: any[];
    interestChangeIntervals: any[];
    cpi: number;
    cpiChangeIntervals: any[];
    principalEliminationStrategy: string;

    private _result: LoanResult;
    get result(): LoanResult {
        return this._result;
    }

    private get monthlyInterest(): number {
        return (Number(this.interest) / 100) / 12;
    }

    fixedPrincipalPayment(principal: number, period: number) {
        if (period <= 0) {
            return 0;
        }
        return principal / period;
    }

    annuity(C: number, i: number, n: number) {
        if (this.amortizationScheduleType === paymentType.FIXED_PRINCIPAL) { // קרן שווה
            return this.principal / (this.period - this.gracePeriod); // TODO: Move from here
        }

        if (i == 0) {
            return C / n;
        }
        return C * (i / (1 - (1 + i) ** (-n))); // שפיצר
    }

    constructor(
        {name, amortizationScheduleType, principal, interestType, interest, period, gracePeriod, principalEliminationChangeIntervals, interestChangeIntervals, cpi, cpiChangeIntervals, principalEliminationStrategy}: LoanOptions
    ) {
        this.name = name;
        this.amortizationScheduleType = amortizationScheduleType;
        this.principal = principal;
        this.interestType = interestType;
        this.interest = interest;
        this.period = period;
        this.gracePeriod = gracePeriod;
        this.principalEliminationChangeIntervals = principalEliminationChangeIntervals;
        this.interestChangeIntervals = interestChangeIntervals;
        this.cpi = cpi;
        this.cpiChangeIntervals = cpiChangeIntervals;
        this.principalEliminationStrategy = principalEliminationStrategy;
        this._result = this.buildSchedule();
    }

    private buildSchedule(): LoanResult {
        const schedule: ScheduleMonth[] = [];
        if (this.period == 0) {
            return new LoanResult({}, schedule);
        }
        const interestChangeIntervals = this.intervalsToArray(this.interestChangeIntervals, (v) => v / 12 / 100, this.monthlyInterest);
        let cpiChangeIntervals: number[];
        if (this.isIndexedLoan()) {
            cpiChangeIntervals = this.intervalsToArray(this.cpiChangeIntervals, (v) => v / 12 / 100, this.cpi / 12 / 100);
        } else {
            this.cpi = 0;
            cpiChangeIntervals = this.intervalsToArray(this.cpiChangeIntervals, () => 0, 0); // לא צמוד - תאפס מדד
        }

        let principalLeft: number = this.principal;
        const grace = new Array(this.period).fill(0).map((v, i) => (i < this.gracePeriod ? 1 : 0));
        const principalElimination = new Array(this.period)
            .fill(0)
            .map((v, i) => {
                const record = this.principalEliminationChangeIntervals.find(x => x.when - 1 === i);
                if (record) {
                    return record.value;
                }
                return 0;
            });
        let dynamicPeriod = this.period;

        for (let index = 0; index < dynamicPeriod; index += 1) {
            if (this.isIndexedLoan()) {
                principalLeft *= (1 + cpiChangeIntervals[index]); // הצמדה למדד
            }

            let interestPayment = principalLeft * interestChangeIntervals[index];
            if (interestChangeIntervals[index] > 0) {
                interestPayment = interestChangeIntervals[index] * principalLeft
            }

            let thisMonthPayment = 0;
            let principalPart = 0;
            if (this.amortizationScheduleType === paymentType.FIXED_PRINCIPAL) {
                thisMonthPayment = this.fixedPrincipalPayment(principalLeft, dynamicPeriod - index);
                principalPart = thisMonthPayment;
            } else if (this.amortizationScheduleType === paymentType.FIXED_PAYMENT) {
                thisMonthPayment = this.annuity(principalLeft, interestChangeIntervals[index], dynamicPeriod - index);
                principalPart = thisMonthPayment - interestPayment;
            } else if (this.amortizationScheduleType === paymentType.BULLET) {
                thisMonthPayment = this.monthlyInterest * principalLeft;
                principalPart = 0;
                if (dynamicPeriod === index + 1) {
                    thisMonthPayment = principalLeft;
                    principalPart = principalLeft + interestPayment;
                }
            }


            schedule.push({
                month: index + 1,
                principalLeft,
                thisMonthPayment: grace[index] !== 1 ? (this.amortizationScheduleType === paymentType.FIXED_PRINCIPAL ? interestPayment + thisMonthPayment : thisMonthPayment) : interestPayment,
                interestPayment,
                principalPart: grace[index] !== 1 ? principalPart : 0,
                grace: grace[index] !== 1 ? 'No Grace' : 'Grace',
                interest: interestChangeIntervals[index],
                cpi: cpiChangeIntervals[index] * principalLeft,
                interestRatio: thisMonthPayment === 0 ? 0 :   (interestPayment / thisMonthPayment) * 100,
            });
            if (principalElimination[index] && this.amortizationScheduleType !== paymentType.BULLET) {
                if (this.principalEliminationStrategy === 'shortenPeriod') {
                    if (this.amortizationScheduleType === paymentType.FIXED_PRINCIPAL) {
                        dynamicPeriod = (principalLeft - principalElimination[index]) / principalPart;
                    } else {
                        const dividend = Math.log10(1 / (1 - ((principalLeft - principalElimination[index]) * (interestChangeIntervals[index])) / thisMonthPayment));
                        const divider = Math.log10(1 + interestChangeIntervals[index]);
                        const updatedMonths = Math.ceil(dividend / divider);
                        dynamicPeriod = updatedMonths + index;
                    }

                }
                principalLeft -= principalElimination[index];
            }
            if (grace[index] !== 1) {
                principalLeft -= principalPart;
            }
        }
        const totalInterest = schedule.map(s => s.interestPayment).reduce((a, b) => a + b, 0);
        const totalCpi = schedule.map(s => s.cpi).reduce((a, b) => a + b, 0);
        const totals = {
            principal: this.principal,
            totalPayment: this.principal + totalInterest + totalCpi,
            firstPayment: schedule[0].thisMonthPayment,
            averagePayment: schedule.map(s => s.thisMonthPayment).reduce((a, b) => a + b, 0) / this.period,
            maximumPayment: Math.max(...schedule.map(s => s.thisMonthPayment)),
            totalInterest,
            totalCpi,
            ratio: (this.principal + totalInterest) / this.principal
        }
        this._result = {schedule, totals};
        return {schedule, totals};
    }

    private intervalsToArray(intervals: IntervalChange[], transformValue: (input: number) => number, initialValue: number): number[] {
        if (intervals == undefined || intervals.length == 0) {
            return new Array(this.period).fill(initialValue);
        }
        const intervalsSorted = intervals.map(e => e)
            .sort((a, b) => {
                return Number(a.when) - Number(b.when);
            });
        let resArray = Array(intervalsSorted[0].when - 1).fill(initialValue);
        let index: number;
        for (index = 0; index < intervalsSorted.length - 1; index += 1) {
            const from = intervalsSorted[index].when;
            const to = intervalsSorted[index + 1].when;
            resArray = resArray.concat(Array(to - from).fill(transformValue(intervalsSorted[index].value)));
        }
        const {when, value} = intervalsSorted[index];
        if (this.period - when + 1 > 0) {
            resArray = resArray.concat(Array(this.period - when + 1).fill(transformValue(value)));
        }
        return resArray;
    }

    public isIndexedLoan() {
        return this.interestType === loanType.FIXED_INDEXED || this.interestType === loanType.VARIABLE_INDEXED;
    }

    get FixedCpi(): string { // TODO
        return loanType.FIXED_INDEXED;
    }
}
