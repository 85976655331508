<template>
  <v-container fluid class="px-3 px-md-7 pt-10">
    <v-row class="d-flex justify-center align-center text--deep-purple font-weight-light"
           style="font-size: 2em">
      מחשבון תשומות בנייה
    </v-row>
    <v-row class="d-flex flex-column justify-center align-center text--deep-purple font-weight-light">
      <v-text-field
          label="מחיר הנכס"
          placeholder="מחיר הנכס"
          outlined
          dense
          type="number"
          class="field-styles"
          v-model="initialCost"
      ></v-text-field>
      <v-row class="mt-4 field-styles">
        <div class="subtitle-1">מועד חתימה</div>
      </v-row>
      <v-row class="field-styles">
        <v-select
            :items="years"
            v-model="fromYear"
            label="שנה"
        ></v-select>
        <v-select
            :items="months"
            v-model="fromMonth"
            label="חודש"
        ></v-select>
        <span>{{ this.values[fromYear][fromMonth] }}</span>
      </v-row>
      <v-row class="field-styles mt-4">
        <div class="subtitle-1">מועד תשלום</div>
      </v-row>
      <v-row class="field-styles">
        <v-select
            :items="years"
            v-model="toYear"
            label="שנה"
        ></v-select>
        <v-select
            :items="months"
            v-model="toMonth"
            label="חודש"
        ></v-select>
        <span>{{ this.values[toYear][toMonth] }}</span>
      </v-row>
      <v-divider></v-divider>
      <br>
      <v-alert
          border="top"
          colored-border
          color="deep-purple accent-4"
          elevation="2"
          class="field-styles"
          style="font-size: 1.5em; font-weight: bold"
      >
        <v-row no-gutters class="justify-center">
          <Money
              :value="initialCost * indexChange - initialCost"
              :maximum-fraction-digits="0"
              :minimum-fraction-digits="0"/>
        </v-row>
        <v-divider></v-divider>
        <v-row no-gutters class="justify-center">
          <Money
              :value="initialCost * (indexChange)"
              :maximum-fraction-digits="0"
              :minimum-fraction-digits="0"/>
        </v-row>

        <v-divider></v-divider>
        <v-row no-gutters class="justify-center">
          <Money :value="100 * (indexChange - 1)"
                 :maximum-fraction-digits="2"
                 :minimum-fraction-digits="2" symbol="%"/>
        </v-row>
      </v-alert>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <v-btn class="mx-2" color="deep-purple accent-4" small outlined style="z-index: 1"
               @click="snackbar = true; copyToClipboard(currentUrl)">
          <v-icon small left> {{ mdiShareVariant }}</v-icon>
          שיתוף
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="d-flex flex-column justify-center align-center text--deep-purple font-weight-light">
        <v-alert dense type="info" max-width="300px">
          טל"ח - השימוש באחריות המשתמש, הנתונים לקוחים מאתר המידע הממשלתי וייתכנו טעויות
        </v-alert>
    </v-row>
    <v-row>
      <v-snackbar
          v-model="snackbar"
          timeout="5000">
        קישור הועתק!
        <template v-slot:action="{ attrs }">
          <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            סגור
          </v-btn>

        </template>

      </v-snackbar>
    </v-row>
  </v-container>
</template>

<script>
import Money from "@/components/common/Money";

export default {
  components: {Money},
  name: "BuildingIndexView.vue",
  metaInfo: {
    title: 'מחשבון תשומות הבנייה',
    titleTemplate: '%s | כמה כמה',
  },
  data: () => ({
    initialCost: 1000000,
    fromMonth: "1",
    fromYear: "2020",
    toMonth: "12",
    toYear: "2021",
    snackbar: false,
    months: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
    values: {
      "2000": {
        "1": 179.1,
        "2": 179.4,
        "3": 179.9,
        "4": 180,
        "5": 180.7,
        "6": 183.7,
        "7": 186.5,
        "8": 186.2,
        "9": 185.8,
        "10": 182.4,
        "11": 183.1,
        "12": 183.5,
        "year": 2000,
        "avg": 182.5
      },
      "2001": {
        "1": 184,
        "2": 183.4,
        "3": 183.1,
        "4": 182.9,
        "5": 183.1,
        "6": 185.3,
        "7": 187.5,
        "8": 187.8,
        "9": 187.2,
        "10": 185,
        "11": 185.1,
        "12": 185.4,
        "year": 2001,
        "avg": 185
      },
      "2002": {
        "1": 186.7,
        "2": 188,
        "3": 188.3,
        "4": 189.7,
        "5": 191.1,
        "6": 194.5,
        "7": 197.3,
        "8": 198,
        "9": 196,
        "10": 195.2,
        "11": 196.2,
        "12": 197.6,
        "year": 2002,
        "avg": 193.2
      },
      "2003": {
        "1": 199.2,
        "2": 200.1,
        "3": 200.5,
        "4": 200,
        "5": 198.7,
        "6": 200.3,
        "7": 202.8,
        "8": 203.6,
        "9": 204.8,
        "10": 201.7,
        "11": 202.8,
        "12": 202.3,
        "year": 2003,
        "avg": 201.4
      },
      "2004": {
        "1": 204.8,
        "2": 101.3,
        "3": 102.8,
        "4": 103.2,
        "5": 103.6,
        "6": 103.7,
        "7": 104.1,
        "8": 103.8,
        "9": 103.9,
        "10": 103.2,
        "11": 103.4,
        "12": 103.6,
        "year": 2004,
        "avg": 103
      },
      "2005": {
        "1": 104.4,
        "2": 104.5,
        "3": 104.8,
        "4": 105.1,
        "5": 105.3,
        "6": 105.8,
        "7": 108.8,
        "8": 111.7,
        "9": 112,
        "10": 111.4,
        "11": 110.4,
        "12": 109.7,
        "year": 2005,
        "avg": 107.8
      },
      "2006": {
        "1": 111,
        "2": 111.4,
        "3": 112,
        "4": 112.8,
        "5": 113.5,
        "6": 114.6,
        "7": 115.6,
        "8": 116.4,
        "9": 116.9,
        "10": 116.5,
        "11": 116.5,
        "12": 116.2,
        "year": 2006,
        "avg": 114.5
      },
      "2007": {
        "1": 117.3,
        "2": 117.5,
        "3": 117.8,
        "4": 117.8,
        "5": 117.6,
        "6": 118.4,
        "7": 119.7,
        "8": 120,
        "9": 120.1,
        "10": 119.9,
        "11": 119.8,
        "12": 119.8,
        "year": 2007,
        "avg": 118.8
      },
      "2008": {
        "1": 120.3,
        "2": 119.8,
        "3": 120.1,
        "4": 121.7,
        "5": 123.6,
        "6": 124.9,
        "7": 126.9,
        "8": 127.1,
        "9": 126.3,
        "10": 124.2,
        "11": 123.9,
        "12": 123.7,
        "year": 2008,
        "avg": 123.5
      },
      "2009": {
        "1": 123.1,
        "2": 123.1,
        "3": 123.1,
        "4": 123.2,
        "5": 123.2,
        "6": 123.3,
        "7": 123.7,
        "8": 123.6,
        "9": 123.6,
        "10": 123.3,
        "11": 123.5,
        "12": 123.7,
        "year": 2009,
        "avg": 123.4
      },
      "2010": {
        "1": 124.1,
        "2": 124.7,
        "3": 125.5,
        "4": 126.6,
        "5": 126.9,
        "6": 126.6,
        "7": 126.5,
        "8": 127.1,
        "9": 127.3,
        "10": 127.4,
        "11": 127.8,
        "12": 128.5,
        "year": 2010,
        "avg": 126.6
      },
      "2011": {
        "1": 129.3,
        "2": 130.1,
        "3": 130.1,
        "4": 130.1,
        "5": 131.1,
        "6": 132,
        "7": 132.3,
        "8": 100.3,
        "9": 100.6,
        "10": 100.7,
        "11": 100.9,
        "12": 100.8,
        "year": 2011,
        "avg": 99.6
      },
      "2012": {
        "1": 101.1,
        "2": 101.6,
        "3": 101.9,
        "4": 102.6,
        "5": 102.7,
        "6": 103,
        "7": 103.3,
        "8": 103.7,
        "9": 103.7,
        "10": 103.7,
        "11": 104,
        "12": 104.1,
        "year": 2012,
        "avg": 103
      },
      "2013": {
        "1": 104.6,
        "2": 104.8,
        "3": 104.8,
        "4": 104.6,
        "5": 104.9,
        "6": 105.1,
        "7": 105.1,
        "8": 105.2,
        "9": 105.4,
        "10": 105.5,
        "11": 105.6,
        "12": 105.6,
        "year": 2013,
        "avg": 105.1
      },
      "2014": {
        "1": 105.9,
        "2": 105.9,
        "3": 105.8,
        "4": 105.9,
        "5": 106,
        "6": 105.9,
        "7": 105.8,
        "8": 105.9,
        "9": 105.8,
        "10": 106.1,
        "11": 106.3,
        "12": 106.4,
        "year": 2014,
        "avg": 106
      },
      "2015": {
        "1": 106.5,
        "2": 106.4,
        "3": 106.6,
        "4": 106.6,
        "5": 106.6,
        "6": 106.6,
        "7": 106.7,
        "8": 106.9,
        "9": 107,
        "10": 107.1,
        "11": 107,
        "12": 107.4,
        "year": 2015,
        "avg": 106.8
      },
      "2016": {
        "1": 107.7,
        "2": 107.5,
        "3": 107.5,
        "4": 107.8,
        "5": 108,
        "6": 108.1,
        "7": 108.5,
        "8": 108.5,
        "9": 108.8,
        "10": 108.8,
        "11": 109,
        "12": 109,
        "year": 2016,
        "avg": 108.3
      },
      "2017": {
        "1": 109,
        "2": 109.2,
        "3": 109.2,
        "4": 109.4,
        "5": 109.4,
        "6": 109.6,
        "7": 109.8,
        "8": 110.3,
        "9": 110.5,
        "10": 110.7,
        "11": 110.8,
        "12": 111.1,
        "year": 2017,
        "avg": 109.9
      },
      "2018": {
        "1": 111.7,
        "2": 112,
        "3": 112.1,
        "4": 112.4,
        "5": 112.5,
        "6": 112.6,
        "7": 112.9,
        "8": 113.1,
        "9": 113.1,
        "10": 113.2,
        "11": 113.2,
        "12": 113.3,
        "year": 2018,
        "avg": 112.7
      },
      "2019": {
        "1": 114,
        "2": 114.1,
        "3": 114.1,
        "4": 114.2,
        "5": 114.3,
        "6": 114.3,
        "7": 114.3,
        "8": 114.4,
        "9": 114.4,
        "10": 114.3,
        "11": 114.3,
        "12": 114.5,
        "year": 2019,
        "avg": 114.3
      },
      "2020": {
        "1": 114.7,
        "2": 114.7,
        "3": 114.9,
        "4": 114.8,
        "5": 114.7,
        "6": 114.7,
        "7": 114.7,
        "8": 114.8,
        "9": 114.7,
        "10": 114.6,
        "11": 114.6,
        "12": 115.1,
        "year": 2020,
        "avg": 114.8
      },
      "2021": {
        "1": 115.8,
        "2": 116,
        "3": 116.6,
        "4": 117.5,
        "5": 118.3,
        "6": 119,
        "7": 119.3,
        "8": 120,
        "9": 120.3,
        "10": 120.6,
        "11": 121.2,
        "12": 121.5,
        "year": 2021,
        "avg": 118.8
      },
      "2022": {
        "1": 122.5,
        "2": 123.1,
        "3": 124.3,
        "4": 125.5,
        "5": 126.1,
        "6": 126.8,
        "7": 127.4,
        "8": 127.5,
        "9": 127.5,
        "10": 127.5,
        "11": 127.3,
        "12": 127.3,
        "year": 2022
      },
      "2023": {
        "1": 128.8,
        "year": 2023
      }
    }
  }),
  computed: {
    years: function () {
      return Object.keys(this.values);
    },
    currentUrl: function () {
      return window.location.href;
    },
    indexChange: function () {
      return this.values[this.toYear][this.toMonth] / this.values[this.fromYear][this.fromMonth];
    }
  },
  methods: {
    fieldUpdated() {
      this.$router.replace({
        name: 'buildingIndexView',
        params: {
          initialCost: this.initialCost,
          fromYear: this.fromYear,
          fromMonth: this.fromMonth,
          toYear: this.toYear,
          toMonth: this.toMonth
        }
      }).catch(() => {
      });
    },
    copyToClipboard(str) {
      const el = document.createElement('textarea');  // Create a <textarea> element
      el.value = str;                                 // Set its value to the string that you want copied
      el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
      el.style.position = 'absolute';
      el.style.left = '-9999px';                      // Move outside the screen to make it invisible
      document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
      const selected =
          document.getSelection().rangeCount > 0        // Check if there is any content selected previously
              ? document.getSelection().getRangeAt(0)     // Store selection if found
              : false;                                    // Mark as false to know no selection existed before
      el.select();                                    // Select the <textarea> content
      document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
      document.body.removeChild(el);                  // Remove the <textarea> element
      if (selected) {                                 // If a selection existed before copying
        document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
        document.getSelection().addRange(selected);   // Restore the original selection
      }
    }
  },
  watch: {
    initialCost: function () {
      this.fieldUpdated();
    },
    period: function () {
      this.fieldUpdated();
    },
    monthlyCost: function () {
      this.fieldUpdated();
    },
    rent: function () {
      this.fieldUpdated();
    },
  },
  beforeMount() {
    ///compound-monthlyCost/:initialCost?/:monthlyCost?/:period/:rent?
    //this.$store.commit('toggleDrawerState', false);
    let inputs = this.$route.params;
    this.initialCost = Number(inputs.initialCost || 1500000);
    this.period = Number(inputs.period || 240);
    this.monthlyCost = Number(inputs.monthlyCost || 400);
    this.rent = Number(inputs.rent || 4800);

  }
}
</script>

<style scoped>
.field-styles {
  margin-top: 5px;
  max-width: 300px;
  width: 300px;
}

.v-select {
  max-width: 100px;
  margin: 5px;
}
</style>