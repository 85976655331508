<template>
  <v-btn
      text
      @click="logout"
  >
    התנתק
    <v-icon>{{mdiLogout}}</v-icon>
  </v-btn>
</template>
<script>
export default {
  name: 'Logout',
  props: {
    Logout: {},
    user: {}
  },
  methods: {
    logout() {
      this.$store.dispatch("logout", );
    }
  }
}
</script>
