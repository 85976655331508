<template>
  <v-container>
    <v-row class="d-flex justify-center align-center text--deep-purple font-weight-light fill-height" style="font-size: 3em">
      Release Notes
    </v-row>
    <v-row class="d-flex justify-center align-center text--deep-purple font-weight-light fill-height" style="font-size: 1em">
        <a href="mailto:contactus@kamakama.app?subject=Please%20enroll%20me%20to%20API%20access&body=Hi%20KamaKama%2C%20please%20enroll%20me%20to%20API%20access">Request Early Access
        </a>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ReleaseNotesView"
}
</script>

<style scoped>

</style>