<template>
  <v-app-bar app color="white" elevation="0">
    <v-progress-linear
        :reverse="$vuetify.rtl"
        :indeterminate="this.displayLoadingBar"
        :active="this.displayLoadingBar"
        absolute
        bottom
        color="deep-purple accent-4"
    ></v-progress-linear>
    <v-app-bar-nav-icon @click="drawerState = !drawerState" v-if="this.$vuetify.breakpoint.mdAndDown"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <v-btn
        class="d-none d-sm-flex"
        to="/api"
        text>
      <span class="mr-2"></span>
      <v-icon>{{ mdiApi }}</v-icon>
    </v-btn>
    <v-btn
        to="/contactus"
        text
    >
      <span class="mr-2">צור קשר</span>
    </v-btn>

    <Login v-if="!user.loggedIn" :user="user"/>
    <v-menu
        bottom
        transition="slide-y-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
            text
            class="float-left px-0"
            v-on="on"
            depressed
        >
          <v-list
              :user="user"
              dense
              nav
              class="py-0 text--white accent-4"
          >
            <v-list-item>

              <v-list-item-content>
                <v-list-item-title>{{ user.user || "אנונימי" }}</v-list-item-title>
                <v-list-item-subtitle v-if="user.user">משתמש בכיר</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-avatar class="ma-0">
                <img src="https://randomuser.me/api/portraits/men/81.jpg" alt="profile picture placeholder" width="40" height="40" />
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title>החשבון שלי</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>{{ user.email }}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <Logout v-if="user.loggedIn" :user="user"/>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import Login from "@/components/Login";
//import UserProfile from "@/components/UserProfile";
import Logout from "@/Logout";

export default {
  components: {
    Logout,
    Login,
    //UserProfile,
  },
  computed: {
    ...mapState({
      mortgageList: (state) => state.mortgageList,
      user: (state) => state.user,
    }),
    ...mapGetters(["displayLoadingBar"]),
    drawerState: {

      get() {
        return this.$store.getters.drawerState
      },
      set(v) {
        return this.$store.commit('toggleDrawerState', v)
      }
    }
  },
};
</script>
