import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify';
import vSelect from 'vue-select'
import GAuth from 'vue-google-oauth2'
import {store} from './store/store'
import '@/styles/styles.scss'
import VueMeta from 'vue-meta'



Vue.config.productionTip = false

// TODO: Extract to a class
const gauthOption = {
    clientId: '202277078325-f69fia0v12j73m8msuejnk5871j0pp3a.apps.googleusercontent.com',
    scope: 'profile email',
    prompt: 'select_account'
}
Vue.use(GAuth, gauthOption)
Vue.use(VueMeta)
Vue.component('v-select-alt', vSelect)

import {
    mdiCalculator,
    mdiLogout,
    mdiFacebook,
    mdiTwitter,
    mdiContentDuplicate,
    mdiCalendar,
    mdiAccountCircle,
    mdiPlusCircleOutline,
    mdiDotsVertical,
    mdiMinusCircleOutline,
    mdiHelpCircleOutline,
    mdiAccount,
    mdiApi,
    mdiHome,
    mdiSubdirectoryArrowLeft,
    mdiShareVariant,
    mdiContentSave,
    mdiFileOutline,
    mdiDotsHorizontal,
    mdiDelete,
    mdiPlus,
    mdiSwapHorizontal,
    mdiHeart
} from "@mdi/js";


Vue.mixin({
    data: function() {
        return {
            mdiCalculator,
            mdiLogout,
            mdiFacebook,
            mdiTwitter,
            mdiContentDuplicate,
            mdiCalendar,
            mdiAccountCircle,
            mdiPlusCircleOutline,
            mdiDotsVertical,
            mdiMinusCircleOutline,
            mdiHelpCircleOutline,
            mdiAccount,
            mdiApi,
            mdiHome,
            mdiSubdirectoryArrowLeft,
            mdiShareVariant,
            mdiContentSave,
            mdiFileOutline,
            mdiDotsHorizontal,
            mdiDelete,
            mdiPlus,
            mdiSwapHorizontal,
            mdiHeart
        }
    }
})

new Vue({
    router,
    vuetify,
    store,
    render: h => h(App)
}).$mount('#app')