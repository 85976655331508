<template>
    <div class="d-flex justify-center align-center text--deep-purple font-weight-light fill-height" style="font-size: 3vw">
      <div class>
        בקרוב - ההלוואות שלי
      </div>
    </div>
</template>

<script>
export default {
  name: "LoansView"
}
</script>

<style scoped>

</style>