<template>
  <span>
  {{
    value ?
      value.toLocaleString("en", {
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits,
      }) : 0
    }}
  {{symbol}}
    </span>
</template>

<script>
export default {
  props: {
    value: Number,
    symbol: {
      type: String,
      default: '₪'
    },
    minimumFractionDigits: {
      default: 0,
      type: Number
    },
    maximumFractionDigits: {
      default: 0,
      type: Number
    }
  },
  name: "Money"
}
</script>

<style scoped>

</style>
