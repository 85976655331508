<template>
  <v-container fluid>
    <v-row class="d-flex justify-center align-center mt-3" style="max-width: 640px; margin: auto">
      <v-alert
          border="top"
          colored-border
          color="deep-purple accent-4"
          elevation="2"
      >
        Welcome to <span class="logo text--deep-purple grow">KamaKama</span> <br>
      </v-alert>
    </v-row>
    <v-row class="d-flex justify-center">
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdoGQyAiVK6YjuZ817Ua3nlTJMTNnGNYx8d2B_Wmirvqcj3aA/viewform?embedded=true" width="640" height="912" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ContactUs"
}
</script>

<style scoped>

</style>