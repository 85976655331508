<template>
  <v-navigation-drawer elevation="0" app :right="$vuetify.rtl" :width="drawerState ? 256 : 56"
                       touchless :value="subMenuOpen" :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm">
    <v-row
        class="fill-height"
        no-gutters
        align-center
        justify-space-between
        column

    >
      <v-navigation-drawer
          color="#f4f5fa"
          mini-variant
          mini-variant-width="56"
          :right="$vuetify.rtl"
          elevation="0"
          :value="menuOpen"
          :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
          touchless
          hide-overlay
          @input="syncDrawerState"
      >
        <v-list>
          <v-list-item>
            <v-app-bar-nav-icon @click="drawerState = !drawerState"></v-app-bar-nav-icon>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="mt-3" link :to="'/m/'" :disabled="currentRouteName === 'mortgageView'">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">

                <v-icon  v-bind="attrs" v-on="on" :color="currentRouteName === 'mortgageView' ? '#6200ea' : null">
                  {{ mdiHome }}</v-icon>
              </template>
              המשכנתאות שלי
            </v-tooltip>
          </v-list-item>
<!--          <v-list-item link :to="'/l/'">-->
<!--            <v-tooltip left>-->
<!--              <template v-slot:activator="{ on, attrs }">-->
<!--                <i class="flaticon-loan" v-bind="attrs" v-on="on"></i>-->
<!--              </template>-->
<!--              בקרוב - ההלוואות שלי-->
<!--            </v-tooltip>-->
<!--          </v-list-item>-->
          <v-list-item class="mt-3" link :to="'/c/'"
                       :disabled="currentRouteName === 'calculatorsView'">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
<!--                <i class="flaticon-calculator" v-bind="attrs" v-on="on"></i>-->

                <v-icon  v-bind="attrs" v-on="on" :color="currentRouteName === 'calculatorsView' ? '#6200ea' : null">{{
                    mdiCalculator
                  }}</v-icon>
              </template>
              מחשבונים
            </v-tooltip>
          </v-list-item>
          <v-spacer/>
          <v-divider></v-divider>
<!--          <v-list-item link :to="'/knowledge-base/'" justify-end>-->
<!--            <v-tooltip left>-->
<!--              <template v-slot:activator="{ on, attrs }">-->
<!--                <i-->
<!--                    class="flaticon-speech-bubble"-->
<!--                    v-bind="attrs"-->
<!--                    v-on="on"-->
<!--                ></i>-->
<!--              </template>-->
<!--              מרכז הידע-->
<!--            </v-tooltip>-->
<!--          </v-list-item>-->
        </v-list>
      </v-navigation-drawer>
      <!-- TODO: make static route names-->
      <v-list class="subNavDrawer pr-3" v-if="drawerState" nav>
        <router-link to="/" style="text-decoration: none"><span class="logo text--deep-purple grow">KamaKama</span></router-link>
        <v-list dense
                prepend-icon="$vuetify.icons.mdiHeart" class="grow">
          <h4>כל המחשבונים</h4>
          <v-list-item
              link
              :to="'/c/compound-interest'"

          >
            <v-icon small left>{{ mdiSubdirectoryArrowLeft }}</v-icon>
            מחשבון ריבית דריבית
          </v-list-item>
          <v-list-item
              link
              :to="'/m/'"
          >
            <v-icon small left>{{ mdiSubdirectoryArrowLeft }}</v-icon>
            מחשבון משכנתא
          </v-list-item>
          <v-list-item
              link
              :to="'/c/rental-yield'"
              :disabled="currentRouteName === 'rentalYieldView'"
          >
            <v-icon small left>{{ mdiSubdirectoryArrowLeft }}</v-icon>
            תשואת נכס
          </v-list-item>
          <v-list-item
              link
              :to="'/c/building-index'"
              :disabled="currentRouteName === 'buildingIndexView'"
          >
            <v-icon small left>{{ mdiSubdirectoryArrowLeft }}</v-icon>
            תשומות הבניה
          </v-list-item>
        </v-list>
        <v-list v-if="currentRouteName === 'mortgageView'" dense prepend-icon="$vuetify.icons.mdiHeart" class="grow">
          <h4>משכנתאות שמורות</h4>
          <v-btn
              class="mx-2 text--deep-purple"
              elevation="0"
              small
              plain
              style="z-index: 1"
              @click="newMortgage"
          >
            <v-icon small left>{{ mdiPlusCircleOutline }}</v-icon>
            הוסף משכנתא
          </v-btn
          >
          <v-list-item
              v-for="mortgageList in user.userData.mortgageLists"
              v-bind:key="mortgageList.id"
              link
              :to="'/m/' + mortgageList.id"
          >
            <v-icon small left>{{ mdiSubdirectoryArrowLeft }}</v-icon>
            {{ mortgageList.name }}
          </v-list-item>
        </v-list>
        <v-list v-if="currentRouteName === 'loansView'" dense prepend-icon="$vuetify.icons.mdiHeart" class="grow">
          <h4>ההלוואות שלי</h4>
          <v-btn
              class="mx-2 text--deep-purple"
              elevation="0"
              small
              plain
              style="z-index: 1"
          >
            <v-icon small left>{{ mdiPlusCircleOutline }}</v-icon>
            הוסף הלוואה
          </v-btn
          >
          <v-list-item
              link
          >
            <v-icon small left>{{ mdiSubdirectoryArrowLeft }}</v-icon>
            בקרוב
          </v-list-item>
        </v-list>

      </v-list>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import {mapState, mapGetters, mapActions} from "vuex";


export default {
  computed: {
    subMenuOpen: function () {
      if (this.$vuetify.breakpoint.smAndDown) { // Mobile
        return this.drawerState;
      }
      return true;
    },
    menuOpen: function () {
      if (this.$vuetify.breakpoint.smAndDown) { // Mobile
        return this.drawerState;
      }
      // Desktop
      return true;
    },
    ...mapState({
      mortgageList: (state) => state.mortgageList,
      user: (state) => state.user,
    }),
    ...mapGetters(["pageTitle", "version"]),
    currentRouteName: function () {
      return this.$route.name;
    },
    drawerState: {
      get() {
        return this.$store.getters.drawerState
      },
      set(v) {
        return this.$store.commit('toggleDrawerState', v)
      }
    }
  },
  methods: {
    // When user clicks on the overlay, the drawer is closed but drawerState remains true, not updated
    syncDrawerState: function (state) {
      return this.$store.commit('toggleDrawerState', state)
    },
    ...mapActions({
      newMortgage: "newMortgage",
    }),
  },
  data: () => ({}),
};
</script>
<style>
.v-list-item--active i {
  color: #6200EA;
}

.v-navigation-drawer__border {
  background-color: #ffffff !important;
}
</style>
