<template>
  <button class="large" id="google-signin-button"></button>
</template>

<script>
export default {
  name: "GoogleLogin",
  async mounted() {
    // console.log("here")
    // const googleUser = await this.$gAuth.signIn()
    // const profile = googleUser.getBasicProfile()
    // console.log(profile)
    //this.$store.dispatch("loginWithGoogle", googleUser);
    window.gapi.signin2.render("google-signin-button", {
      onsuccess: this.onSignIn,
      theme: "light",
      longtitle: true,
      width: "338px",
      height: "44px",
    });
  },
  methods: {
    onSignIn(user) {
      const profile = user.getBasicProfile();
      console.log(profile);
      this.$store.dispatch("loginWithGoogle", user); // TODO: replace with action (map action)
    },
  },
};
</script>
